import { Anchor, Information } from '@oneflare/flarekit/lib/components';
import Button from '@oneflare/flarekit/lib/components/Button';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const ButtonWrapperStyled = styled.div`
  display: flex;
  padding: 0 24px;
  justify-content: space-around;

  ${media.smUp`
    padding: 0;
  `}
`;

export const TipsStyled = styled(Paragraph)`
  ${({ theme }) => theme.color.gunMetal};

  font-size: 16px;
  margin: 0;
`;

export const CancelButtonStyled = styled(Button)`
  width: 232px;
  margin-right: 16px;
`;
export const ConfirmButtonStyled = styled(Button)`
  width: 232px;
`;

export const InfoContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.tin};
  padding: ${({ theme }) => theme.spacing.md};
  margin: ${({ theme }) => theme.spacing.md} 0 0;
  border-radius: 8px;
`;

export const InfoIconStyled = styled(Information)`
  height: 47px;
  width: 47px;
`;

export const LabelStyled = styled(Paragraph)`
  font-size: 16px;
  padding-left: ${({ theme }) => theme.spacing.md};
`;

export const AnchorStyled = styled(Anchor).attrs(() => ({
  href: 'tel:1300441593',
  label: '1300 441 593'
}))``;
