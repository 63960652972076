import { TrackBusinessProfileViewOptions } from '@oneflare/web-analytics';
import isEmpty from 'lodash/isEmpty';
import dynamic from 'next/dynamic';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
  BppHeader,
  BppBody,
  BppFooter
} from '@oneflare-page-components/BusinessPublicProfile/components';
import { NearbyLocations } from '@oneflare-page-components/BusinessPublicProfile/components/BppHeader/components';
import {
  buildLocalBusinessSchema,
  buildBusinessProfileTracker
} from '@oneflare-page-components/BusinessPublicProfile/utils';
import { AnalyticsPage } from 'lib/analytics/constants';
import { PageViewType, useTrackPageView } from 'lib/analytics/trackPageViews';
import NotificationBanner from 'shared/components/NotificationBanner';
import { NotificationBannerMsgStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import PageHeader from 'shared/components/PageHeader/PageHeader';
import { useBreakpoint } from 'shared/utils/hooks';
import type {
  BusinessPublicProfileModel,
  BusinessPublicProfileReviewModel
} from 'types/oneflare.com.au/businessPublicProfile';
import { type User } from 'types/oneflare.com.au/user';

import {
  BusinessPublicProfileContainerStyled,
  BreadcrumbsStyled,
  BusinessPublicProfileLayoutWrapperStyled,
  ColumnStyled
} from './styled/BusinessPublicProfile';

const DynamicFooter = dynamic(() => import('shared/components/Footer'));
const BppReviewModal = dynamic(() => import('@oneflare-page-components/BusinessPublicProfile/components/ReviewModal'));

const ProfileNotifcationBanner = ({ feedbackStatus }: { feedbackStatus: string; }) => {
  switch (feedbackStatus) {
    case 'published':
      return (
        <NotificationBanner status={NOTIFICATION_STATUS.SUCCESS}>
          <NotificationBannerMsgStyled>
            Your review has been published
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      );
    case 'verifying':
      return (
        <NotificationBanner status={NOTIFICATION_STATUS.INFO}>
          <NotificationBannerMsgStyled>
            Thanks for confirming, your review is with our support team to
            verify
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      );
    default:
      return null;
  }
};

const MemoizedProfileNotificationBanner = memo(ProfileNotifcationBanner);

type BusinessPublicProfileProps = {
  feedbackId: string;
  businessSlug: string;
  staticRender: boolean;
  feedbackStatus: string;
  business: BusinessPublicProfileModel;
  initialReviewData: BusinessPublicProfileReviewModel;
};

const BusinessPublicProfile = ({
  business,
  feedbackId,
  staticRender,
  businessSlug,
  feedbackStatus,
  initialReviewData
}: BusinessPublicProfileProps) => {
  const [showReviewModal, setShowReviewModal] = useState(Boolean(feedbackId));
  const reviewsSectionRef = useRef<HTMLElement | null>(null);
  const [user, setUser] = useState<User>(null);
  useTrackPageView(PageViewType.BusinessProfile, setUser, {
    businessDetailId: businessSlug
  } as TrackBusinessProfileViewOptions);

  const isLoggedin = !!user;
  const isSameBusiness = business.id === Number(user?.business?.id);
  const isStickyHeader = !isLoggedin || !user?.isBusiness;
  const tracker = useMemo(
    () => buildBusinessProfileTracker(business.id),
    [business.id]
  );

  const breadcrumbsNav = business.breadcrumbsV2?.map(({ title, url }) => ({
    title,
    link: url
  }));

  useEffect(
    () =>
      tracker.interaction('pageView', {
        label: business.featuredListing
          ? 'premium_business_profile'
          : 'business_profile'
      }),
    [business.featuredListing, tracker]
  );

  // Show nearby locations based on user landing on mobile
  // or desktop preventing server/client html mismatch
  const isLgUp = useBreakpoint('lg');
  const [showNearbyLocations, setShowNearbyLocations] = useState(() => {
    if (!business.primaryCategory) return false;
    if (isMobile) return true;
    return false;
  });

  useEffect(() => {
    setShowNearbyLocations(!isLgUp);
  }, [isLgUp]);

  return (
    <>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            buildLocalBusinessSchema(business, initialReviewData)
          )
        }}
      />
      <MemoizedProfileNotificationBanner feedbackStatus={feedbackStatus} />
      <PageHeader
        initiatedAuthBeginPageName={AnalyticsPage.BusinessProfile}
        jobFormInitiatedPageName={AnalyticsPage.BusinessProfile}
      />
      <BusinessPublicProfileContainerStyled>
        {!isEmpty(business.breadcrumbsV2) && (
          <BreadcrumbsStyled lastItemIsLinked navigation={breadcrumbsNav} />
        )}
        <BusinessPublicProfileLayoutWrapperStyled>
          <ColumnStyled>
            <BppHeader
              business={business}
              businessSlug={businessSlug}
              staticRender={staticRender}
              isStickyHeader={isStickyHeader}
              reviewsSectionRef={reviewsSectionRef}
              tracker={tracker}
            />
          </ColumnStyled>
          <ColumnStyled>
            <BppBody
              business={business}
              initialReviewData={initialReviewData}
              isLoggedin={isLoggedin}
              isSameBusiness={isSameBusiness}
              reviewsSectionRef={reviewsSectionRef}
              tracker={tracker}
            />
          </ColumnStyled>
          <ColumnStyled>
            <BppFooter isStickyHeader={isStickyHeader} />
          </ColumnStyled>
          {showNearbyLocations && (
            <ColumnStyled>
              <NearbyLocations
                category={business.primaryCategory.name}
                nearbyCategoryLocations={business.nearbyCategoryLocations}
              />
            </ColumnStyled>
          )}
        </BusinessPublicProfileLayoutWrapperStyled>
      </BusinessPublicProfileContainerStyled>
      {feedbackId && (
        <BppReviewModal
          feedbackId={feedbackId}
          handleClose={() => {
            setShowReviewModal(false);
          }}
          show={showReviewModal}
        />
      )}
      <DynamicFooter />
    </>
  );
};

export default memo(BusinessPublicProfile);
