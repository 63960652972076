import media from '@oneflare/flarekit/lib/libs/media';
import { styled, css, ExecutionContext } from 'styled-components';

export const BreadcrumbsStyled = styled.section<{
  $showInMobile?: boolean;
  $kind?: 'hero' | 'category' | 'product';
}>`
  position: relative;
  text-align: center;
  ${({ $showInMobile }) => !$showInMobile && 'display: none;'}

  ${({ $kind }) => {
    if ($kind === 'hero') {
      return css`
        display: block;
        background: rgba(38, 49, 51, 0.4);
        top: -8px;
        padding: 4px 8px 5px;
        border-radius: 4px;
        align-self: flex-start;
      `;
    }
    return null;
  }}

  ${media.mdUp`
    display: block;
    font-size: 34px;

    ${({ $kind }: ExecutionContext & {
      $kind?: 'hero' | 'category' | 'product';
    }) => {
      if ($kind === 'hero') {
        return css`
            align-self: center;
          `;
      } if ($kind === 'category') {
        return 'padding: 16px 0 24px 0;';
      }
      return null;
    }}
  `}
`;

export const BreadcrumbsList = styled.ol<{
  $kind?: 'hero' | 'category' | 'product';
}>`
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: ${({ $kind }) => (($kind === 'category' || $kind === 'hero') ? 'left' : 'center')};
  display: ${({ $kind }) => (($kind === 'hero') ? 'flex' : 'inline')};
  flex-wrap: wrap;
  line-height: 1.5;
`;

export const BreadcrumbsItem = styled.li<{
  $withLargeFont?: boolean;
  $withColorTheme?: boolean;
  $withFontWeight?: boolean;
  $kind?: 'hero' | 'category' | 'product';
}>`
  font-size: ${({ $withLargeFont }) => ($withLargeFont ? '16px' : '14px')};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ $withColorTheme, theme }) => ($withColorTheme ? theme.color.gunMetal : theme.color.silver)};
  font-weight: ${({ $withFontWeight }) => ($withFontWeight ? 600 : 700)};
  display: inline;

  &:last-child {
    svg {
      display: none;
    }
  }

  ${({ $kind, theme }) => {
    if ($kind === 'hero') {
      return `color: ${theme.color.white};`;
    } if ($kind === 'category') {
      return css`
        font-weight: bold;
      `;
    }
    return null;
  }}
`;

export const BreadcrumbsLink = styled.a<{
  $withColorTheme?: boolean;
  $kind?: 'hero' | 'category' | 'product';
}>`
  color: ${({ $withColorTheme, theme }) => {
    return ($withColorTheme ? theme.color.gunMetal : theme.color.silver);
  }};
  font-weight: 400;
  text-decoration: none;

  ${({ $kind, theme }) => {
    if ($kind === 'hero') {
      return `color: ${theme.color.white};`;
    }
    return null;
  }}

  :visited,
  &:hover {
    text-decoration: underline;
    color: ${({ $withColorTheme, theme }) => ($withColorTheme ? ('tertiaryLight' in theme.color && theme.color.tertiaryLight) : theme.color.silver)};
  }

  ${({ $kind, theme }) => {
    if ($kind === 'hero') {
      return css`
        color: ${theme.color.white};

        :visited,
        &:hover {
          color: ${theme.color.white};
        }
      `;
    }
    return null;
  }}
`;
