import { gql } from '@apollo/client';

import { ICurrentUser } from 'types/oneflare.com.au/user';
import { CategoryModel } from 'types/shared';

const CURRENT_USER_ATTRIBUTES = gql`
  fragment currentUserFields on CurrentUser {
    user {
      id
      name
      firstName
      lastName
      displayName
      email
      avatarUrl
      experiments
      business {
        cachedSlug
      }
    }
  }
`;

export const GET_CURRENT_BUSINESS_PROFILE = gql`
  query GetCurrentBusinessProfile {
    currentBusinessProfile {
      id
      name
      abn
      address
      suburb
      postcode
      verified
      state
      locationId
      website
      landline
      contactNumber
      yearFounded
      hideMobileFromPublic
      hideEmailFromPublic
      hideLandlineFromPublic
      showSuburbOnly
      requestReviewUrl
      feedbackCount
      feedbackAvg
      featuredListingAdditionalProfessionsLimit
      primaryCategory {
        id
        name
      }
      featuredListingAdditionalProfessions {
        id
        name
      }
      businessVerificationStatus
      isBusinessOwner
    }
    currentUser {
      ...currentUserFields
    }
  }
  ${CURRENT_USER_ATTRIBUTES}
`;

export type CurrentBusinessProfileModel = {
  id: number;
  name: string;
  abn: string;
  address: string;
  suburb: string;
  postcode: string;
  verified: boolean;
  state: string;
  locationId: number;
  website: string;
  landline: null;
  contactNumber: string;
  yearFounded: number;
  hideMobileFromPublic: boolean;
  hideEmailFromPublic: boolean;
  hideLandlineFromPublic: boolean;
  showSuburbOnly: boolean;
  requestReviewUrl: string;
  feedbackCount: number;
  feedbackAvg: number;
  featuredListingAdditionalProfessionsLimit: number;
  primaryCategory: {
    id: string;
    name: string;
  };
  featuredListingAdditionalProfessions: Array<CategoryModel>;
  businessVerificationStatus: string;
  isBusinessOwner: boolean;
}

export type GetCurrentBusinessProfileResponse = {
  currentBusinessProfile: CurrentBusinessProfileModel;
  currentUser: ICurrentUser;
};

export const GET_CURRENT_BUSINESS_DESCRIPTION = gql`
  query GetCurrentBusinessDescription {
    currentBusinessProfile{
      id
      description
    }
  }
`;

export const GET_CURRENT_BUSINESS_NAME = gql`
  query GetCurrentBusinessName {
    currentBusinessProfile{
      id
      name
      subscription {
        id
        plan
      }
    }
    currentUser {
      ...currentUserFields
    }
  }
  ${CURRENT_USER_ATTRIBUTES}
`;

export const GET_CURRENT_BUSINESS_PROFILE_RATING = gql`
  query GetCurrentBusinessProfileRating {
    currentBusinessProfile {
      id
      profileScore
      profileScoreBreakdown {
        scoreName
        htmlClassName
        scoreOutOf
        helperText
      }
    }
    currentUser {
      ...currentUserFields
    }
  }
  ${CURRENT_USER_ATTRIBUTES}
`;

export const GET_CURRENT_BUSINESS_PROFESSION_AND_PLAN = gql`
  query GetCurrentBusinessProfessionAndPlan {
    currentBusinessProfile{
      id
      primaryCategory {
        name
      }
      subscription {
        id
        plan
      }
      featuredListingAdditionalProfessions {
        id
        name
      }
    }
  }
`;

export const GET_CURRENT_BUSINESS_OPEN_HOURS = gql`
  query GetCurrentBusinessOpenHours {
    currentBusinessProfile{
      id
      openHours: openingHours {
        closed
        day
        openStatus
        openHours
        formattedOpenHours
      }
      available247
      openPublicHolidays
    }
  }
`;

export const GET_CURRENT_BUSINESS_SOCIAL_MEDIA_LINKS = gql`
  query GetCurrentBusinessSocialMediaLinks {
    currentBusinessProfile {
      id
      socialMediaLinks {
        platform
        url
      }
    }
  }
`;

export const GET_CURRENT_BUSINESS_SELLING_POINTS = gql`
  query GetCurrentBusinessSellingPoints {
    currentBusinessProfile{
      id
      uniqueSellingPoints
    }
  }
`;

export const GET_CURRENT_BUSINESS_PAYMENT_METHODS = gql`
  query GetCurrentBusinesPaymentMethods {
    currentBusinessProfile{
      id
      acceptedPaymentMethods {
        visa
        mastercard
        amex
        paypal
        cash
        directDeposit
        cheque
      }
    }
  }
`;

export const GET_CURRENT_BUSINESS_FAQ = gql`
  {
    currentBusinessProfile{
      id
      name
      faqs {
        profileQuestion {
          id
          question
        }
        id
        answer
      }
    }
    currentUser {
      ...currentUserFields
    }
  }
  ${CURRENT_USER_ATTRIBUTES}
`;

export const GET_BUSINESS_IMAGE_URLS = gql`
  {
    currentBusinessProfile {
      id
      name
      gallery {
        id
        filename
        name
        thumb
        original
        position
      }
    }
  }
`;

export const GET_CURRENT_BUSINESS_CREDENTIALS = gql`
  {
    currentBusinessProfile {
      id
      name
      categorisedCredentials {
        licenseVerificationDocuments {
          id
          type
          name
          currentState
          documentOriginalFileName
        }
        certificateVerificationDocuments {
          id
          type
          name
          currentState
          documentOriginalFileName
        }
        insuranceVerificationDocuments {
          id
          type
          name
          currentState
          documentOriginalFileName
        }
      }
      awards {
        id
        year
        name
        category
        slug
        introDescription
      }
      badges {
        id
        name
      }
    }
    currentUser {
      ...currentUserFields
    }
  }
  ${CURRENT_USER_ATTRIBUTES}
`;

export const GET_CURRENT_BUSINESS_CONTACT = gql`
  {
    currentBusinessProfile {
      id
      address
      suburb
      state
      locationId
      postcode
      abn
      businessVerificationStatus
      canonicalAddress {
        canonicalAddressId
        canonicalAddress
        postcode
        stateTerritory
        localityName
        fullAddress
        longitude
        latitude
      }
      website
      landline
      contactNumber
      yearFounded
      organizationSize
      organizationType
      hideMobileFromPublic
      hideEmailFromPublic
      hideLandlineFromPublic
      showSuburbOnly
    }
    currentUser {
      ...currentUserFields
    }
  }
  ${CURRENT_USER_ATTRIBUTES}
`;
