import PropTypes from 'prop-types';
import { memo } from 'react';

import { ProgressStyled } from './styled/ProgressBar';

const propTypes = {
  progress: PropTypes.number
};

const defaultProps = {
  progress: null
};

const ProgressBar = ({ progress }) => (
  <ProgressStyled progress={progress} />
);

ProgressBar.propTypes = propTypes;

ProgressBar.defaultProps = defaultProps;

export default memo(ProgressBar);
