import { useMemo } from 'react';

import { publicRuntimeConfig } from 'lib/utils/Environment';
import { titleize } from 'shared/utils/helpers';
import type { BreadCrumbsPropsModel } from 'types/shared';

import {
  BreadcrumbsStyled,
  BreadcrumbsList,
  BreadcrumbsItem,
  BreadcrumbsLink
} from './styled/BreadcrumbsStyled';


type BreadCrumbsProps = {
  className?: string;
  kind?: 'hero' | 'category';
  lastItemIsLinked?: boolean;
  showInMobile?: boolean;
  withColorTheme?: boolean;
  withFontWeight?: boolean;
  withLargeFont?: boolean;
} & BreadCrumbsPropsModel;

const Breadcrumbs = ({
  kind,
  lastItemIsLinked,
  navigation,
  withColorTheme,
  withFontWeight,
  className,
  withLargeFont,
  showInMobile
} : BreadCrumbsProps) =>  {

  const buildBreadcrumbListSchema = useMemo(() => navigation.map((item, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': `${publicRuntimeConfig.SITE_URL}${item.link}`.replace(/\/$/, ''),
        name: titleize(item.title)
      }
    };
  }), [navigation]);

  return (
    <BreadcrumbsStyled
      className={className}
      $kind={kind}
      $showInMobile={showInMobile}
    >
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              ${JSON.stringify(buildBreadcrumbListSchema)}
            ]
          }`
        }}
      />
      <BreadcrumbsList $kind={kind}>
        {navigation.map((item, index) => (
          (navigation.length - 1 === index && !lastItemIsLinked) ? (
            <BreadcrumbsItem
              key={item.title}
              className="BreadcrumbsStyled__BreadcrumbsItem"
              $withColorTheme={withColorTheme}
              $withFontWeight={withFontWeight}
              $withLargeFont={withLargeFont}
              $kind={kind}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          ) : (
            <BreadcrumbsItem
              className="BreadcrumbsStyled__BreadcrumbsItem"
              $withColorTheme={withColorTheme}
              $withFontWeight={withFontWeight}
              $withLargeFont={withLargeFont}
              $kind={kind}
              key={item.title}
            >
              <BreadcrumbsLink
                $withColorTheme={withColorTheme}
                $kind={kind}
                href={item.link}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              {' / '}
            </BreadcrumbsItem>
          )))}
      </BreadcrumbsList>
    </BreadcrumbsStyled>
  );
};

export default Breadcrumbs;
