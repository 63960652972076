import PropTypes from 'prop-types';
import { memo } from 'react';

import ProgressBar from 'shared/components/ProgressBar';

import { FILE_STATUS_OBJECT } from './data';
import {
  DocItemWrapperStyled,
  FileWrapperStyled,
  FileStyled,
  IconStyled,
  FileNameStyled,
  StatusTagStyled,
  RemoveStyled,
  RemoveDisabledStyled
} from './styled/DocItem';

const propTypes = {
  currentState: PropTypes.string,
  disabled: PropTypes.bool,
  docKind: PropTypes.oneOf(['invoice', 'credentials']),
  handleRemoveClick: PropTypes.func.isRequired,
  hasSingleFileLimit: PropTypes.bool.isRequired,
  name: PropTypes.string,
  percentage: PropTypes.number
};

const defaultProps = {
  currentState: 'none',
  disabled: false,
  docKind: 'credentials',
  name: '',
  percentage: null
};

const DocItem = ({
  name,
  percentage,
  currentState,
  disabled,
  docKind,
  handleRemoveClick,
  hasSingleFileLimit
}) => {
  const progress = percentage && percentage < 100 ? (
    <ProgressBar progress={percentage} />
  ) : null;

  const { tag, kind } = FILE_STATUS_OBJECT[currentState];

  return (
    <DocItemWrapperStyled
      percentage={percentage}
      hasSingleFileLimit={hasSingleFileLimit}
    >
      <FileWrapperStyled>
        <IconStyled percentage={percentage} />
        <FileStyled>
          <FileNameStyled>{name || 'Unknown file name'}</FileNameStyled>
          {docKind === 'credentials' && (
            <StatusTagStyled
              size="small"
              border={false}
              kind={kind}
            >
              {tag}
            </StatusTagStyled>
          )}
        </FileStyled>
        {(!percentage || percentage === 100) && (
          disabled
            ? (
              <RemoveDisabledStyled>Remove</RemoveDisabledStyled>
            )
            : (
              <RemoveStyled onClick={handleRemoveClick}>
                Remove
              </RemoveStyled>
            )
        )}
      </FileWrapperStyled>
      {percentage && progress}
    </DocItemWrapperStyled>
  );
};

DocItem.propTypes = propTypes;
DocItem.defaultProps = defaultProps;

export default memo(DocItem);
