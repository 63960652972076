import { memo } from 'react';

import { AnalyticsPage } from 'lib/analytics/constants';
import FreeQuotesForm from 'shared/components/FreeQuotesForm';

import { GetFreeQuotesWrapperStyled } from './styled/BppFooter';

const BppFooter = ({ isStickyHeader = false }) => {
  return (
    <GetFreeQuotesWrapperStyled $isStickyHeader={isStickyHeader}>
      <FreeQuotesForm kind="narrow"
        jobFormInitiatedPageName={AnalyticsPage.BusinessProfile} 
      />
    </GetFreeQuotesWrapperStyled>
  );
};

export default memo(BppFooter);
